import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		alignItems: "flex-start",
		justifyContent: "center",
		boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
	},
	accordionBar: {
		minHeight: "40px",
		height: "40px",
	},
	heading: {
		fontFamily: "'Avenir LT W01_85 Heavy1475544', sans-serif",
		fontSize: "1.875rem",
		lineHeight: "3.375rem",
		margin: "0",
	},
	accordionDetails: {
		flexDirection: "column",
		maxWidth: "108rem",
		margin: "0 3rem",
		padding: "0 0 3rem",
		"@media screen and (min-width:768px)": {
			margin: "0 10rem"
		},
		"@media screen and (min-width:960px)": {
			margin: "0 auto"
		},
	}
}));

export default function TranscriptAccordion({ siteAudience, idName, transcriptTitle, transcriptContent }) {
	const [isOpen, setIsOpen] = useState(false);

	React.useEffect(() => {
		function scrollToStudyDesign() {
			const bounds = document.getElementById('study-design').getBoundingClientRect();
			let scrollPosition = window.pageYOffset + bounds.top;
			let stickyHdr;
			if (window.matchMedia("(min-width: 768px)").matches) {
				stickyHdr = document.getElementById('site-navigation').getBoundingClientRect().height;
			}
			else {
				stickyHdr = document.getElementById('hpp-navigation').getBoundingClientRect().height;
			}
			scrollPosition = scrollPosition - stickyHdr;
			window.scrollTo({top: scrollPosition, behavior:'smooth'});
		}

		if (typeof window !== 'undefined') {
			const myHash = window.location.hash;
			if (myHash === '#study-design' && idName === 'study-design') {
				setIsOpen(true);
				scrollToStudyDesign();
			}
		}
	},[idName]);
	const classes = useStyles();
	return (
		<div className={[classes.root, siteAudience].join(' ')}>
			<Accordion
				square={true}
				expanded={isOpen}
			>
				<AccordionSummary
					className={classes.accordionBar}
					expandIcon={<ExpandMoreIcon viewBox="6 6 12 12" />}
					aria-controls="panel-content"
					id={idName != null ?
						idName : "panel-header"}
					onClick={() => setIsOpen(!isOpen)}
				>
					<h3 className={classes.heading}>{transcriptTitle}</h3>
				</AccordionSummary>
				<AccordionDetails className={classes.accordionDetails}>
					{transcriptContent}
				</AccordionDetails>
			</Accordion>
		</div>

	);
}